import LeftColumn from "@/page/distributor/other/components/leftColumn/1.0.0/index.vue";
export default {
  data() {
    return {
      activeNav: null,
      navList: ["商务合作", "投诉建议", "诚聘英才"],
      imgList: [
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2021/03/10/business_cooperation.png?x-oss-process=style/yinzhilv-default",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020-05-13/联系我们.jpg?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020-05-13/join-us.jpg?x-oss-process=style/webp"
      ],
      
      linkManList: [
        { areaText: '其他地区', linkPhone: '18924982835', linkName: '黄秋婷' },
      ]
    };
  },
  components: { LeftColumn },
  methods: {
    getActive(val) {
      this.activeNav = val;
    }
  },
  created() {
    this.activeNav = this.$route.query.type;
  }
};